import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import 'bootstrap/dist/css/bootstrap.min.css';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Landing = React.lazy(() => import('./views/pages/landingPage/LandingScreen'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const LoginWithOTP = React.lazy(() => import('./views/pages/loginwithotp/LoginWithOTP'))
const ForgotPassword = React.lazy(() => import('./views/pages/forgotpassword/ForgotPassword'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ContactUs = React.lazy(()=> import('./views/pages/landingPage/CustomerSupport'))
const PrivacyPolicies = React.lazy(()=> import('./views/privacypolicies/PrivacyPolicies'))
const TermsAndCondition = React.lazy(()=> import('./views/termsandconditions/TermsAndConditions'))
const EvacLanding = React.lazy(()=> import('./views/pages/evac/LandingScreen'))
const EvacSupport = React.lazy(()=> import('./views/pages/evac/CustomerSupport'))
const Ocpp2Landing = React.lazy(()=> import('./views/pages/ocpp2/LandingScreen'))
const Ocpp2Support = React.lazy(()=> import('./views/pages/ocpp2/CustomerSupport'))
const Pesolarmarketing = React.lazy(()=> import('./views/pages/pesolar/LandingScreen'))
const PesolarSupport = React.lazy(()=> import('./views/pages/pesolar/CustomerSupport'))

const EcabMarketing = React.lazy(()=> import('./views/pages/ecab/Landing'))
const EcabSupport = React.lazy(()=> import('./views/pages/ecab/CustomerSupport'))
const EcabPrivacy = React.lazy(()=> import('./views/pages/ecab/Privacy'))


class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/landing" name="Landing Page" element={<Landing />} />
            <Route exact path="/support" name="Support" element={<ContactUs />} />
            <Route exact path="/evacmarketing" name="Marketing page" element={<EvacLanding />} />
            <Route exact path="/evacsupport" name="Evac Support" element={<EvacSupport />} />
            <Route exact path="/ocppmarketing" name="Marketing page" element={<Ocpp2Landing />} />
            <Route exact path="/ocppsupport" name="Ocpp2.0 Support" element={<Ocpp2Support />} />
            <Route exact path="/pesolarmarketing" name="Pesolar Marketing page" element={<Pesolarmarketing />} />
            <Route exact path="/pesolarsupport" name="Pesolar Support" element={<PesolarSupport />} />
            <Route exact path="/ecabmarketing" name="Ecab marketing" element={<EcabMarketing />} />
            <Route exact path="/ecabsupport" name="Ecab Support" element={<EcabSupport />} />
            <Route exact path="/ecabprivacy" name="Ecab Privacy" element={<EcabPrivacy />} />
            <Route exact path="/privacy" name="Privacy" element={<PrivacyPolicies />} />
            <Route exact path="/terms" name="Terms" element={<TermsAndCondition />} />
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/loginwithotp" name="Login Page" element={<LoginWithOTP />} />
            <Route exact path="/forgotpassword" name="Login Page" element={<ForgotPassword />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
